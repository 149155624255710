@import "./env";
@import "../../../submodules/ui/packages/ui-base/src/scss/mv/settings.colors";
@import "./functions";

// Keyboard
$keyboard-width: $target-width / 1.2;

// Home Page
$home--univers--padding-size: getVw(160px);

// OQP
$footer-height: getVh(136px);
$header-height: getVh(128px);
$header-shadow: rgba(0, 0, 0, 0.15);

// Video Component
$video-width: getVw(1570px);
$video-height: getVh(1007px);
$video-width-small: getVw(754px);
$video-height-small: getVh(504px);

$button-back-width: getVw(130px);

.qrclass {
    img {
        width: 18vw;
        height: 18vw;
    }
}
